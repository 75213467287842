import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowForwardIos,
  MdBookmark,
  MdBookmarkBorder,
  MdClose,
  MdMenu,
  MdOutlineShoppingCart,
  MdPerson,
  MdPersonOutline,
  MdShoppingCart,
} from "react-icons/md";

import "../styles/Product.css";
import { isEmpty } from "../functions/isEmpty";
import { useLocation, useNavigate, useParams } from "react-router";
import Cart from "./Cart";

export default function Product({
  isMobile,
  apiURL,
  authToken,
  products,
  cart,
  setCart,
}) {
  let navigate = useNavigate();
  let location = useLocation().pathname;
  const { id } = useParams();
  const [size, setSize] = useState(1);
  const [activeProduct, setActiveProduct] = useState({
    titel: "",
    bilder: [],
    beschreibung: "",
    verkaufspreis: "1000",
  });
  const [activeURL, setActiveURL] = useState("");

  useEffect(() => {
    if (!isEmpty(products)) {
      products.map((product) => {
        if (product.id == id) {
          setActiveProduct(product);
          setActiveURL(
            !isEmpty(JSON.parse(product.bilder))
              ? JSON.parse(product.bilder)[0].url
              : ""
          );
        }
      });
    }
  }, [products]);

  const handleAddBtn = (obj) => {
    let helpArr = [...cart];
    for (let i = 0; i < size; i++) {
      helpArr.push(obj);
    }
    setCart(helpArr);
    navigate("/");
  };

  function convertToEuroCent(eurocentStr) {
    // Convert the string to an integer
    let eurocentInt = parseInt(eurocentStr, 10);

    // Calculate the euro and cent values
    let euro = Math.floor(eurocentInt / 100);
    let cent = eurocentInt % 100;

    // Return the values as an array (or you can return an object if preferred)
    return [euro, cent];
  }

  if (!isMobile) {
    return (
      <div className="product_desktop">
        <div className="product_topnav_desktop">
          <div className="product_topnavcenteralign">
            <div className="product_topnav_left">{activeProduct.titel}</div>
            <div className="product_topnav_right" onClick={() => navigate("/")}>
              <CloseIcon />
            </div>
          </div>
        </div>
        <div className="product_body_desktop">
          <div className="product_bodycenteralign">
            <div className="product_body_photos">
              <div className="product_body_photos_previewbox">
                <img
                  src={activeURL}
                  className="product_body_photos_previewbox_img"
                />
              </div>
              <div className="product_body_photos_selectionScrollBar">
                {!isEmpty(activeProduct.bilder) &&
                  JSON.parse(activeProduct.bilder).map((img) => (
                    <div
                      className={
                        img.url == activeURL
                          ? "product_body_photos_selectionScrollBar_imgbox_active"
                          : "product_body_photos_selectionScrollBar_imgbox"
                      }
                      onClick={() => setActiveURL(img.url)}>
                      <img
                        src={img.url}
                        className="product_body_photos_selectionScrollBar_imgbox_img"
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className="product_body_meta">
              <div className="product_body_meta_title">
                {activeProduct.titel}
                <br />
                {convertToEuroCent(activeProduct.verkaufspreis) + " €"}
              </div>
              <div className="product_body_meta_description">
                {activeProduct.beschreibung}
              </div>
              <div className="product_bottombar_desktop">
                <div className="product_bottombar_left">
                  <select
                    onChange={(e) => setSize(e.target.value)}
                    className="product_bottombar_left_select">
                    {Array.from(
                      { length: parseInt(activeProduct.vorrat) },
                      (v, i) => (
                        <option key={i} value={i + 1} selected={size == i + 1}>
                          {i + 1} Stk.
                          {" (" +
                            convertToEuroCent(
                              parseInt(activeProduct.verkaufspreis) * (i + 1)
                            ) +
                            "€)"}
                        </option>
                      )
                    )}
                  </select>
                </div>
                <div
                  className="product_bottombar_right"
                  onClick={() => handleAddBtn(activeProduct)}>
                  hinzufügen
                </div>
              </div>
            </div>
            <div className="">
              <div className="home_sidebar_sticky">
                <Cart
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  products={products}
                  cart={cart}
                  setCart={setCart}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="product">
      <div className="product_topnav">
        <div className="product_topnav_left">{activeProduct.titel}</div>
        <div className="product_topnav_right" onClick={() => navigate("/")}>
          <CloseIcon />
        </div>
      </div>
      <div className="product_body">
        <div className="product_body_photos">
          <div className="product_body_photos_previewbox">
            <img
              src={activeURL}
              className="product_body_photos_previewbox_img"
            />
          </div>
          <div className="product_body_photos_selectionScrollBar">
            {!isEmpty(activeProduct.bilder) &&
              JSON.parse(activeProduct.bilder).map((img) => (
                <div
                  className={
                    img.url == activeURL
                      ? "product_body_photos_selectionScrollBar_imgbox_active"
                      : "product_body_photos_selectionScrollBar_imgbox"
                  }
                  onClick={() => setActiveURL(img.url)}>
                  <img
                    src={img.url}
                    className="product_body_photos_selectionScrollBar_imgbox_img"
                  />
                </div>
              ))}
          </div>
        </div>
        <div className="product_body_meta">
          <div className="product_body_meta_title">
            {activeProduct.titel}
            <br />
            {convertToEuroCent(activeProduct.verkaufspreis) + " €"}
          </div>
          <div className="product_body_meta_description">
            {activeProduct.beschreibung}
          </div>
        </div>
      </div>
      <div className="product_bottombar">
        <div className="product_bottombar_left">
          <select
            onChange={(e) => setSize(e.target.value)}
            className="product_bottombar_left_select">
            {Array.from({ length: parseInt(activeProduct.vorrat) }, (v, i) => (
              <option key={i} value={i + 1} selected={size == i + 1}>
                {i + 1} Stk.
                {" (" +
                  convertToEuroCent(
                    parseInt(activeProduct.verkaufspreis) * (i + 1)
                  ) +
                  "€)"}
              </option>
            ))}
          </select>
        </div>
        <div
          className="product_bottombar_right"
          onClick={() => handleAddBtn(activeProduct)}>
          hinzufügen
        </div>
      </div>
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 30px;
  color: #474747;
`;

const ArrowForwardIcon = styled(MdArrowForwardIos)`
  font-size: 18px;
  color: #474747;
`;

const CloseIcon = styled(MdClose)`
  font-size: 25px;
  color: #222222;
`;
