import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowForwardIos,
  MdBookmark,
  MdBookmarkBorder,
  MdClose,
  MdMenu,
  MdOutlineShoppingCart,
  MdPerson,
  MdPersonOutline,
  MdShoppingCart,
} from "react-icons/md";

import "../styles/Checkout.css";
import { isEmpty } from "../functions/isEmpty";
import Spinner from "../components/CTA/Spinner.js";
import { useLocation, useNavigate } from "react-router";
import { useRef } from "react";
import Cart from "./Cart.js";

export default function Checkout({
  isMobile,
  apiURL,
  authToken,
  products,
  cart,
  setCart,
}) {
  let navigate = useNavigate();
  let location = useLocation().pathname;
  const searchParams = new URLSearchParams(useLocation().search);
  const success = searchParams.get("success");
  const [cartWithCount, setCartWithCount] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [companyname, setCompanyname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [streetname, setStreetname] = useState("");
  const [streetnumber, setStreetnumber] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [delivery_status, setDelivery_status] = useState("");
  const [delivery_tracking_number, setDelivery_tracking_number] = useState("");
  const [delivery_sent_at_unixdate, setDelivery_sent_at_unixdate] =
    useState("");

  const [sum, setSum] = useState(0);

  useEffect(() => {
    let sumTotal = 0;
    cart.map((product) => {
      sumTotal = sumTotal + parseInt(product.verkaufspreis);
    });
    setSum(sumTotal);
  }, [cart]);

  const formRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        const inputs = Array.from(formRef.current.querySelectorAll("input"));
        const index = inputs.indexOf(event.target);
        if (index > -1 && index < inputs.length - 1) {
          inputs[index + 1].focus();
          event.preventDefault(); // Prevent form submission on Enter
        }
      }
    };

    const formElement = formRef.current;
    if (!isEmpty(formElement)) {
      formElement.addEventListener("keydown", handleKeyDown);
    }

    // Cleanup event listener on component unmount
    return () => {
      if (!isEmpty(formElement)) {
        formElement.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, []);

  const handlePayButtonClicked = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    let dateNow = Math.floor(Date.now() / 1000);

    let order = [];
    cart.map((item) => {
      order.push(item.id);
    });

    const resa = await fetch(`${apiURL}/api/orders/index.php`, {
      method: "PUT",
      headers: {
        //   Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        createdAt: dateNow,
        companyname: companyname,
        firstname: firstname,
        lastname: lastname,
        streetname: streetname,
        streetnumber: streetnumber,
        zip: zip,
        city: city,
        country: "Germany",
        email: email,
        payment_amount_eurocent: sum,
        ordered_products: JSON.stringify(order),
      }),
    });

    await resa.text().then((value) => uploadEnds(value));
  };

  const uploadEnds = () => {
    setIsLoading(false);
    navigate("/checkout?success=true");
  };

  if (!isEmpty(success)) {
    if (success == "true") {
      return (
        <div className="checkout_desktop" ref={formRef}>
          <div className="checkout_topnav_desktop">
            <div className="checkout_topnavcenteralign">
              <div className="checkout_topnav_left">Bestellung erfolgreich</div>
              <div
                className="checkout_topnav_right"
                onClick={() => navigate("/")}>
                <CloseIcon />
              </div>
            </div>
          </div>

          <div className="checkout_body_desktop">
            <div className="checkout_bodycenteralign">
              Bestellung eingegangen
            </div>
          </div>

          <div className="">
            <div className="home_sidebar_sticky">
              <Cart
                isMobile={isMobile}
                apiURL={apiURL}
                authToken={authToken}
                products={products}
                cart={[]}
                setCart={setCart}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return <div>Ein Fehler ist aufgetreten</div>;
    }
  }

  if (!isMobile) {
    return (
      <div className="checkout_desktop" ref={formRef}>
        <div className="checkout_topnav_desktop">
          <div className="checkout_topnavcenteralign">
            <div className="checkout_topnav_left">Kasse</div>
            <div
              className="checkout_topnav_right"
              onClick={() => navigate("/cart")}>
              <CloseIcon />
            </div>
          </div>
        </div>
        <div className="checkout_body_desktop">
          <div className="checkout_bodycenteralign">
            <div className="checkout_section">
              <div className="checkout_body_title">Lieferdaten</div>
              <div className="checkout_body_fullrow">
                <div className="checkout_body_fullrow_title">
                  Firmenname {"(optional)"}
                </div>
                <input
                  className="checkout_body_fullrow_input"
                  placeholder="Firmenname (optional)"
                />
              </div>
              <div className="checkout_body_fullrow">
                <div className="checkout_body_fullrow_divider">
                  <div className="checkout_body_fullrow_half">
                    <div className="checkout_body_fullrow_half_title">
                      Vorname
                    </div>
                    <input
                      className="checkout_body_fullrow_half_input"
                      placeholder="Vorname"
                      type="text"
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                    />
                  </div>
                  <div className="checkout_body_fullrow_half">
                    <div className="checkout_body_fullrow_half_title">
                      Nachname
                    </div>
                    <input
                      className="checkout_body_fullrow_half_input"
                      placeholder="Nachname"
                      type="text"
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="checkout_body_fullrow">
                <div className="checkout_body_fullrow_divider">
                  <div className="checkout_body_fullrow_half">
                    <div className="checkout_body_fullrow_half_title">
                      Straße
                    </div>
                    <input
                      className="checkout_body_fullrow_half_input"
                      placeholder="Straße"
                      type="text"
                      value={streetname}
                      onChange={(e) => setStreetname(e.target.value)}
                    />
                  </div>
                  <div className="checkout_body_fullrow_half">
                    <div className="checkout_body_fullrow_half_title">
                      Hausnummer
                    </div>
                    <input
                      className="checkout_body_fullrow_half_input"
                      placeholder="Hausnummer"
                      type="tel"
                      value={streetnumber}
                      onChange={(e) => setStreetnumber(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="checkout_body_fullrow">
                <div className="checkout_body_fullrow_divider">
                  <div className="checkout_body_fullrow_half">
                    <div className="checkout_body_fullrow_half_title">
                      Postleizahl
                    </div>
                    <input
                      className="checkout_body_fullrow_half_input"
                      placeholder="Postleitzahl"
                      type="tel"
                      value={zip}
                      onChange={(e) => setZip(e.target.value)}
                    />
                  </div>
                  <div className="checkout_body_fullrow_half">
                    <div className="checkout_body_fullrow_half_title">
                      Stadt
                    </div>
                    <input
                      className="checkout_body_fullrow_half_input"
                      placeholder="Stadt"
                      type="text"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="checkout_body_fullrow">
                <div className="checkout_body_fullrow_title">
                  E-Mail-Adresse für Bestelldetails
                </div>
                <input
                  className="checkout_body_fullrow_input"
                  placeholder="E-MailAdresse"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="checkout_section">
              <div className="checkout_body_title">Zahlungdaten</div>
            </div>
            <div className="checkout_section">
              <div className="checkout_body_title">Bestellung</div>
            </div>
            <div className="checkout_section">
              {isLoading ? (
                <div className="checkout_body_CTA_loading">
                  <Spinner />
                </div>
              ) : (
                <div
                  className="checkout_body_CTA"
                  onClick={() => handlePayButtonClicked()}>
                  Bestellen
                </div>
              )}
            </div>
            <div className="">
              <div className="home_sidebar_sticky">
                <Cart
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  products={products}
                  cart={cart}
                  setCart={setCart}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="checkout" ref={formRef}>
      <div className="checkout_topnav">
        <div className="checkout_topnav_left">Kasse</div>
        <div
          className="checkout_topnav_right"
          onClick={() => navigate("/cart")}>
          <CloseIcon />
        </div>
      </div>
      <div className="checkout_body">
        <div className="checkout_section">
          <div className="checkout_body_title">Lieferdaten</div>
          <div className="checkout_body_fullrow">
            <div className="checkout_body_fullrow_title">
              Firmenname {"(optional)"}
            </div>
            <input
              className="checkout_body_fullrow_input"
              placeholder="Firmenname (optional)"
              type="text"
              value={companyname}
              onChange={(e) => setCompanyname(e.target.value)}
            />
          </div>
          <div className="checkout_body_fullrow">
            <div className="checkout_body_fullrow_divider">
              <div className="checkout_body_fullrow_half">
                <div className="checkout_body_fullrow_half_title">Vorname</div>
                <input
                  className="checkout_body_fullrow_half_input"
                  placeholder="Vorname"
                  type="text"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                />
              </div>
              <div className="checkout_body_fullrow_half">
                <div className="checkout_body_fullrow_half_title">Nachname</div>
                <input
                  className="checkout_body_fullrow_half_input"
                  placeholder="Nachname"
                  type="text"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="checkout_body_fullrow">
            <div className="checkout_body_fullrow_divider">
              <div className="checkout_body_fullrow_half">
                <div className="checkout_body_fullrow_half_title">Straße</div>
                <input
                  className="checkout_body_fullrow_half_input"
                  placeholder="Straße"
                  type="text"
                  value={streetname}
                  onChange={(e) => setStreetname(e.target.value)}
                />
              </div>
              <div className="checkout_body_fullrow_half">
                <div className="checkout_body_fullrow_half_title">
                  Hausnummer
                </div>
                <input
                  className="checkout_body_fullrow_half_input"
                  placeholder="Hausnummer"
                  type="tel"
                  value={streetnumber}
                  onChange={(e) => setStreetnumber(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="checkout_body_fullrow">
            <div className="checkout_body_fullrow_divider">
              <div className="checkout_body_fullrow_half">
                <div className="checkout_body_fullrow_half_title">
                  Postleizahl
                </div>
                <input
                  className="checkout_body_fullrow_half_input"
                  placeholder="Postleitzahl"
                  type="tel"
                  value={zip}
                  onChange={(e) => setZip(e.target.value)}
                />
              </div>
              <div className="checkout_body_fullrow_half">
                <div className="checkout_body_fullrow_half_title">Stadt</div>
                <input
                  className="checkout_body_fullrow_half_input"
                  placeholder="Stadt"
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="checkout_body_fullrow">
            <div className="checkout_body_fullrow_title">
              E-Mail-Adresse für Bestelldetails
            </div>
            <input
              className="checkout_body_fullrow_input"
              placeholder="E-MailAdresse"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="checkout_section">
          <div className="checkout_body_title">Zahlungdaten</div>
        </div>
        <div className="checkout_section">
          <div className="checkout_body_title">Bestellung</div>
        </div>
        <div className="checkout_section">
          {isLoading ? (
            <div className="checkout_body_CTA_loading">
              <Spinner />
            </div>
          ) : (
            <div
              className="checkout_body_CTA"
              onClick={() => handlePayButtonClicked()}>
              Bestellen
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 30px;
  color: #474747;
`;

const ArrowForwardIcon = styled(MdArrowForwardIos)`
  font-size: 18px;
  color: #474747;
`;

const CloseIcon = styled(MdClose)`
  font-size: 25px;
  color: #222222;
`;
