import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowForwardIos,
  MdBookmark,
  MdBookmarkBorder,
  MdClose,
  MdMenu,
  MdOutlineShoppingCart,
  MdPerson,
  MdPersonOutline,
  MdShoppingCart,
} from "react-icons/md";

import "../styles/Cart.css";
import { isEmpty } from "../functions/isEmpty";
import { useLocation, useNavigate } from "react-router";

export default function Cart({
  isMobile,
  apiURL,
  authToken,
  products,
  cart,
  setCart,
}) {
  let navigate = useNavigate();
  let location = useLocation().pathname;
  const [cartWithCount, setCartWithCount] = useState([]);
  const [sum, setSum] = useState(0);

  function convertToEuroCent(eurocentStr) {
    // Convert the string to an integer
    let eurocentInt = parseInt(eurocentStr, 10);

    // Calculate the euro and cent values
    let euro = Math.floor(eurocentInt / 100);
    let cent = eurocentInt % 100;

    // Return the values as an array (or you can return an object if preferred)
    return [euro, cent];
  }

  useEffect(() => {
    if (
      !isMobile &&
      location != "/checkout" &&
      location.substring(0, 8) != "/product"
    ) {
      navigate("/");
    }
  }, [isMobile]);

  useEffect(() => {
    let helpArr = [...cart];
    let helpArr2 = [];

    helpArr.map((item) => {
      let foundElement = null;
      for (let i = 0; i < helpArr2.length; i++) {
        if (helpArr2[i].id === item.id) {
          foundElement = helpArr2[i];
        }
      }

      if (!foundElement) {
        helpArr2.push({ product: item, count: 1, id: item.id });
      } else {
        helpArr2.map((el) => {
          if (el.id == item.id) {
            el.count = el.count + 1;
          }
        });
      }
    });

    setCartWithCount(helpArr2);

    let sumTotal = 0;
    helpArr.map((product) => {
      sumTotal = sumTotal + parseInt(product.verkaufspreis);
    });
    setSum(sumTotal);
  }, [cart]);

  const addOrRemoveAnObject = (addOrRemove, object) => {
    if (addOrRemove == "add") {
      let helpArr = [...cart];
      helpArr.push(object);
      setCart(helpArr);
    } else {
      let helpArr = [...cart];

      const index = helpArr.findIndex((element) => element === object);
      if (index !== -1) {
        helpArr.splice(index, 1);
      }

      setCart(helpArr);
    }
  };

  return (
    <div className="cart">
      <div className={isMobile ? "cart_topnav" : "cart_topnav_desktop"}>
        <div className="cart_topnav_left">Warenkorb</div>
        <div className="cart_topnav_right" onClick={() => navigate("/")}>
          <CloseIcon />
        </div>
      </div>
      <div className="cart_body">
        <div className="cart_body_cartlist">
          {cartWithCount.map((product) => (
            <div className="cart_body_cartlist_product">
              <div className="cart_body_cartlist_product_top">
                <div className="cart_body_cartlist_product_top_left">
                  <img
                    src={
                      !isEmpty(product.product.bilder) &&
                      JSON.parse(product.product.bilder)[0].url
                    }
                    className="cart_body_cartlist_product_top_left_img"
                  />
                </div>
                <div className="cart_body_cartlist_product_top_middle">
                  {product.product.titel}
                </div>
                <div className="cart_body_cartlist_product_top_right">
                  {convertToEuroCent(product.product.verkaufspreis) + " €"}
                </div>
              </div>
              <div className="cart_body_cartlist_product_bottom">
                <div className="cart_body_cartlist_product_bottom_left"></div>
                <div className="cart_body_cartlist_product_bottom_right">
                  <div className="cart_body_cartlist_product_bottom_right_count">
                    <div
                      className="cart_body_cartlist_product_bottom_right_count_leftbtn"
                      onClick={() =>
                        addOrRemoveAnObject("remove", product.product)
                      }>
                      -
                    </div>
                    <div className="cart_body_cartlist_product_bottom_right_count_num">
                      {product.count}
                    </div>
                    <div
                      className="cart_body_cartlist_product_bottom_right_count_rightbn"
                      onClick={() =>
                        addOrRemoveAnObject("add", product.product)
                      }>
                      +
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {(isMobile || (!isMobile && location != "/checkout")) && (
        <div className="cart_bottombar">
          <div className="cart_bottombar_sum">
            <div className="cart_bottombar_sum_left">Zwischensumme</div>
            <div className="cart_bottombar_sum_right">
              {convertToEuroCent(sum) + " €"}
            </div>
          </div>
          <div
            className="cart_bottombar_btn"
            onClick={() => navigate("/checkout")}>
            Zur Kasse
          </div>
        </div>
      )}
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 30px;
  color: #474747;
`;

const ArrowForwardIcon = styled(MdArrowForwardIos)`
  font-size: 18px;
  color: #474747;
`;

const CloseIcon = styled(MdClose)`
  font-size: 25px;
  color: #222222;
`;
