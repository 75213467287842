import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./styles/App.css";
import { isEmpty } from "./functions/isEmpty";
import useFetch from "./functions/useFetch";

import Home from "./screens/Home";
import * as authentication from "./functions/authentication";
import useScreenType from "./functions/useScreenType";
import { LanguageProvider } from "./contexts/LanguageContext";
import Product from "./screens/Product";
import Cart from "./screens/Cart";
import Checkout from "./screens/Checkout";
import GuestOrders from "./screens/GuestOrder";
import GuestOrder from "./screens/GuestOrder";

const developerMode = true;

export default function App() {
  let apiURL = "https://cloud.grillchef.app";
  if (developerMode) {
    apiURL = "https://www.grillchef.servist.de";
  }

  /* Auth Token */
  const [authToken, setAuthToken] = useState("");

  /* userdata */
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");

  /* orderdata */
  const [guests, setGuests] = useState([
    {
      name: "Chef(Du)",
      orders: [
        {
          internal_id: "74",
          variationVonID: "",
          titel: "Hareico Grill-Bratwurst",
          zustand: "frisch",
          gewicht: "500",
          bilder:
            '[{"url":"https://www.grillchef.servist.de/api/images/uploads/92b77f51f289f6375f13dc149d20e199.jpeg"}]',
          vorrat: "10",
          bezugspunkt:
            "https://shop.rewe.de/p/hareico-grill-bratwurst-8x62-5g/5807727",
          einkaufspreis: "619",
          verkaufspreis: "1199",
          preis_pro_kilo: "2398",
          category: "Fleisch",
          beschreibung:
            "Herzhaft-würzig mit feinen Kräutern, der Klassiker unter den Grillwürsten.",
          energie_kj: "1.180",
          energie_kcal: "285",
          fett: "25",
          gesaettigt_fett: "10,5",
          einfachungesaettigt_fett: "",
          mehrfachungesaettigt_fett: "",
          kohlenhydrate: "1",
          zucker: "0,5",
          eiweiss: "14",
          salz: "2",
          zutatenliste:
            "Zutaten: Schweinefleisch (90%), Trinkwasser, Speisesalz, Gewürze (enthalten SENF und SELLERIE), Traubenzucker, Glukosesirup, Stabilisator: Natriumcitrate, Rinder-Collagendarm.\nAllergenhinweise: Sellerie, SenfEnthält: Sellerie und Sellerieerzeugnisse. Senf- und Senferzeugnisse.",
          ballaststoffe: "",
          calcium: "20",
          natrium: "0,83",
          nettofuellmenge: "500",
          hersteller: "Döllinghareico GmbH & Co. KG",
          createdAt: "",
          status: "1",
        },
        {
          id: "155",
          internal_id: "61",
          variationVonID: "",
          titel: "Heineken 0,33l EINWEG",
          zustand: "frisch",
          gewicht: "330",
          bilder:
            '[{"url":"https://www.grillchef.servist.de/api/images/uploads/57ab26e2d1df199707abc1fc3da9462d.jpeg"}]',
          vorrat: "10",
          bezugspunkt: "",
          einkaufspreis: "119",
          verkaufspreis: "229",
          preis_pro_kilo: "6,94",
          category: "Getränke",
          beschreibung:
            "Heineken® ist ein goldgelbes Lager, das aus 100 % natürlichen Zutaten gebraut wird und eine besondere Note durch die A-Hefe erhält. Diese einzigartige Hefe verleiht dem Bier ein volles Aroma mit feinen Fruchtnuancen, während der Abgang mild und leicht bitter ist.",
          energie_kj: "176",
          energie_kcal: "42",
          fett: "0",
          gesaettigt_fett: "0",
          einfachungesaettigt_fett: "",
          mehrfachungesaettigt_fett: "",
          kohlenhydrate: "3,2",
          zucker: "< 0",
          eiweiss: "0",
          salz: "<0,01",
          zutatenliste:
            "Zutaten: Wasser, GERSTENMALZ, Hopfenextrakt.\nAllergenhinweise: Enthält: Gerste und Gerstenerzeugnisse (glutenhaltiges Getreide).\nAlkoholgehalt: 5 % Vol.\n",
          ballaststoffe: "",
          calcium: "",
          natrium: "",
          nettofuellmenge: "",
          hersteller: "Heineken Deutschland GmbH",
          createdAt: "",
          status: "1",
        },
      ],
    },
    { name: "Gast 1", orders: [] },
  ]);

  const [cart, setCart] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  /* handle Page */
  const [reload, setReload] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const isMobile = useScreenType();
  const [scrollPosition, setScrollPosition] = useState(0);

  const destroySession = () => {
    try {
      localStorage.removeItem("@jwtmentora");
      setAuthToken();
      setCompanyData([]);
    } catch (e) {
      // saving error
    }
  };

  const getData = () => {
    try {
      const jsonValue = localStorage.getItem("@jwtmentora");
      var resJSON = jsonValue.substring(1, jsonValue.length - 1);
      setAuthToken({ JWT: resJSON });
    } catch (e) {
      // error reading value
    }
  };

  if (isEmpty(authToken) && localStorage.getItem("@jwtmentora")) {
    getData();
  }

  const signIn = async () => {
    let helpobject = {};

    const setJWT = (data) => {
      helpobject = data;
    };

    const storeData = (value) => {
      try {
        localStorage.setItem("@jwtmentora", JSON.stringify(value));
        console.log(value);
      } catch (e) {
        // saving error
      }
    };
    const res = await fetch(`${apiURL}/api/authenticate`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });

    // check if response exists
    if (res.status >= 0 && res.status <= 299) {
      const jwt = await res.text();
      // check if response has jwt
      if (jwt.length > 50) {
        setJWT(jwt);
        storeData(helpobject);
        console.log("localstorage erfolgreich");
        setAuthToken(setAuthToken({ JWT: helpobject }));
        console.log("StoreData ist Da. Redirect!");
      } else {
        alert("Login fehlgeschlagen");
      }
      setIsUploading(false);
    } else {
      // @Todo: Handle errors
      console.log(res.status, res.statusText);
      setIsUploading(false);
    }
  };

  const signUp = async () => {
    let helpobject = {};

    const setJWT = (data) => {
      helpobject = data;
      console.log(helpobject);
    };
    const storeData = (value) => {
      try {
        localStorage.setItem("@jwtmentora", JSON.stringify(value));
        console.log(value);
      } catch (e) {
        // saving error
      }
    };

    const res = await fetch(`${apiURL}/api/signup.php`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        username: username,
      }),
    });

    // check if response exists
    if (res.status >= 0 && res.status <= 299) {
      const jwt = await res.text();
      // check if response has jwt
      if (jwt.length > 50) {
        setJWT(jwt);
        storeData(helpobject);
        console.log("localstorage erfolgreich");
        setAuthToken(setAuthToken({ JWT: helpobject }));
        console.log("StoreData ist Da. Redirect!");
      } else {
        alert("Registrierung fehlgeschlagen");
      }
      setIsUploading(false);
    } else {
      // @Todo: Handle errors
      console.log(res.status, res.statusText);
      setIsUploading(false);
    }
  };

  const {
    data: userData,
    loading: loadingUser,
    error: errorUser,
  } = useFetch(
    `${apiURL}/api/users/`,
    authToken,
    "GET",
    null,
    [authToken] // Additional dependencies, if needed
  );

  const {
    data: companyData,
    loading: loadingCompanyData,
    error: errorCompanyData,
    setData: setCompanyData,
  } = useFetch(
    `${apiURL}/api/companies/?id=` + userData.companyID,
    authToken,
    "GET",
    null,
    [userData, authToken, reload] // Additional dependencies, if needed
  );

  const {
    data: products,
    loading: loadingProducts,
    error: errorProducts,
    setData: setProducts,
  } = useFetch(
    `${apiURL}/api/products/index.php`,
    authToken,
    "GET",
    null,
    [authToken, reload] // Additional dependencies, if needed
  );

  return (
    <div className="app">
      <LanguageProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  products={products}
                  cart={cart}
                  setCart={setCart}
                  searchInput={searchInput}
                  setSearchInput={setSearchInput}
                  scrollPosition={scrollPosition}
                  setScrollPosition={setScrollPosition}
                  guests={guests}
                  setGuests={setGuests}
                />
              }
            />
            <Route
              path="/guest/:id"
              element={
                <GuestOrder
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  products={products}
                  cart={cart}
                  setCart={setCart}
                  searchInput={searchInput}
                  setSearchInput={setSearchInput}
                  scrollPosition={scrollPosition}
                  setScrollPosition={setScrollPosition}
                  guests={guests}
                  setGuests={setGuests}
                />
              }
            />
            <Route
              path="/product/:id"
              element={
                <Product
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  products={products}
                  cart={cart}
                  setCart={setCart}
                />
              }
            />
            <Route
              path="/cart/*"
              element={
                <Cart
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  products={products}
                  cart={cart}
                  setCart={setCart}
                />
              }
            />
            <Route
              path="/checkout/*"
              element={
                <Checkout
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  products={products}
                  cart={cart}
                  setCart={setCart}
                />
              }
            />
          </Routes>
          <></>
        </Router>
      </LanguageProvider>
    </div>
  );
}
