import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowForwardIos,
  MdBookmark,
  MdBookmarkBorder,
  MdClose,
  MdMenu,
  MdOutlineShoppingCart,
  MdPerson,
  MdPersonOutline,
  MdSearch,
  MdShoppingCart,
} from "react-icons/md";

import "../styles/Home.css";
import { isEmpty } from "../functions/isEmpty";
import { useLocation, useNavigate, useParams } from "react-router";
import { useRef } from "react";
import Cart from "./Cart";

export default function GuestOrder({
  isMobile,
  apiURL,
  authToken,
  products,
  cart,
  setCart,
  searchInput,
  setSearchInput,
  scrollPosition,
  setScrollPosition,
  guests,
  setGuests,
}) {
  const { id } = useParams();
  let navigate = useNavigate();
  let location = useLocation().pathname;
  const [closestElement, setClosestElement] = useState(null);
  const scrollbarRef = useRef(null);
  const [searchActive, setSearchActive] = useState(false);

  // Function to save the current scroll position
  const handleNavigateClick = (destination, object) => {
    setScrollPosition(window.scrollY);
    window.scrollTo(0, 0);
    if (destination == "cart") {
      navigate("/cart");
    }
    if (destination == "product") {
      navigate("/product/" + object.id);
    }
  };

  // Effect to scroll to the saved position on component mount
  useEffect(() => {
    window.scrollTo(0, scrollPosition);
  }, [scrollPosition]);

  const categories = [
    "Fleisch",
    "Saucen",
    "Butter",
    "Brot/Buns",
    "Grillequipment",
    "Getränke",
    "Gewürze/Rubs",
    "Gemüse",
    "Vegan/Vegetarisch",
    "Utensilien",
  ];

  const addToCart = (obj) => {
    let helpArr = [...cart];
    let helpArr2 = [...guests];
    helpArr.push(obj);
    setCart(helpArr);

    helpArr2[id].orders.push(obj);
    setGuests(helpArr2);
  };

  const countPrices = () => {
    let totalPrice = 0;
    cart.map((el) => {
      totalPrice = totalPrice + parseInt(el.verkaufspreis);
    });
    return totalPrice;
  };

  function convertToEuroCent(eurocentStr) {
    // Convert the string to an integer
    let eurocentInt = parseInt(eurocentStr, 10);

    // Calculate the euro and cent values
    let euro = Math.floor(eurocentInt / 100);
    let cent = eurocentInt % 100;

    // Return the values as an array (or you can return an object if preferred)
    return [euro, cent];
  }

  const scrollToElement = (id) => {
    const targetElement = document.getElementById(id);

    // Get the top position of the target element
    const elementPosition =
      targetElement.getBoundingClientRect().top + window.pageYOffset;

    // Subtract 100px from the top position
    const offsetPosition = elementPosition - 80;

    // Scroll to the calculated position
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const getClosestElementInViewport = (ids) => {
    function isInViewport(element) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    let closestElement = null;
    let minDistance = Infinity;

    ids.forEach((id) => {
      const element = document.getElementById(id);
      if (element && isInViewport(element)) {
        const distance = element.getBoundingClientRect().top;
        if (distance < minDistance) {
          minDistance = distance;
          closestElement = id;
        }
      }
    });

    return closestElement;
  };

  useEffect(() => {
    const handleScroll = () => {
      const closest = getClosestElementInViewport(categories);
      if (!isEmpty(closest)) {
        setClosestElement(closest);
      }
    };

    // Attach the event listener
    window.addEventListener("scroll", handleScroll);

    // Initial check in case some elements are already in viewport on mount
    handleScroll();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [categories]);

  useEffect(() => {
    if (closestElement) {
      const activeElement = document.getElementById(closestElement + "nav");
      const scrollbar = scrollbarRef.current;

      if (activeElement && scrollbar) {
        const activeElementRect = activeElement.getBoundingClientRect();
        const scrollbarRect = scrollbar.getBoundingClientRect();

        // Check if the active element is not at the very left of the scrollbar's viewport
        if (activeElementRect.left !== scrollbarRect.left) {
          // Scroll the container to bring the active element to the left smoothly
          scrollbar.scrollTo({
            left: activeElement.offsetLeft - scrollbarRect.left,
            behavior: "smooth",
          });
        }
      }
    }

    let searchEl = document.getElementById("searchInput");
    if (!isEmpty(searchEl)) {
      searchEl.addEventListener("keydown", function (event) {
        if (event.key === "Enter") {
          event.preventDefault();
          this.blur(); // Close the keyboard
        }
      });
    }
  }, [closestElement]);

  const getFirstSubProduct = (TopProd) => {
    if (!isEmpty(products)) {
      // Use `find` to locate the first product that matches the condition
      const foundProduct = products.find(
        (product) => product.variationVonID === TopProd.internal_id
      );

      if (foundProduct) {
        console.log(foundProduct); // Logging the found product
        return foundProduct; // Return the found product
      }
    }

    // If no product is found or the products array is empty, return TopProd
    return TopProd;
  };

  if (!isMobile) {
    return (
      <div className="home_desktop">
        {searchActive ? (
          <div className="home_topnav_desktop">
            <div className="home_topnavcenteralign">
              <div className="home_topnav_searchbar">
                <input
                  className="home_topnav_searchbar_input"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  placeholder="Suche nach einem Produkt"
                  autocomplete={"off"}
                  type="text"
                  id={"searchInput"}
                />
                <div
                  className="home_topnav_searchbar_close"
                  onClick={() => setSearchActive(false)}>
                  <CloseIcon />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="home_topnav_desktop">
            <div className="home_topnavcenteralign">{guests[id].name}</div>
          </div>
        )}

        <div className="home_body_desktop">
          <div className="home_body_banner_desktop">
            <video
              key={"bannervideo"} // Add key prop here
              controls={false}
              className="home_body_banner_video"
              preload="metadata"
              id={"bannervideo"}
              autoPlay={true}
              playsInline={true}
              muted={false}
              loop={true}
              poster="https://www.cloud.grillchef.app/api/images/placeholder/grillpartyposter.jpg" // Add poster attribute here
            >
              <source
                key={"bannervideosrc"}
                src="https://www.cloud.grillchef.app/api/images/placeholder/grillparty.mp4"
                type={`video/mp4`}
              />
              Your browser does not support the video tag.
            </video>
            <div className="home_body_banner_overlay">
              <img
                src="../../logo-white.svg"
                className="home_body_banner_overlay_logo"
              />
              Deine perfekte Grillparty
            </div>
          </div>
          <div className="home_bodycenteralign">
            <div className="home_body_products">
              {searchActive ? (
                <>
                  <div className="home_body_products_sectionTitle">
                    Suchergebnisse für {searchInput}
                  </div>
                  {!isEmpty(products) &&
                    searchInput.length > 2 &&
                    products.map((product) => {
                      if (
                        product.titel
                          .toLowerCase()
                          .includes(searchInput.trim().toLowerCase())
                      ) {
                        return (
                          <div
                            className="home_body_products_product"
                            key={product.id}>
                            <div
                              className="home_body_products_product_left"
                              onClick={() =>
                                handleNavigateClick("product", product)
                              }>
                              <img
                                src={JSON.parse(product.bilder)[0].url}
                                className="home_body_products_product_left_img"
                              />
                            </div>
                            <div className="home_body_products_product_right">
                              <div
                                className="home_body_products_product_right_top"
                                onClick={() =>
                                  handleNavigateClick("product", product)
                                }>
                                <div className="home_body_products_product_right_top_left">
                                  {product.titel}
                                </div>
                                <div className="home_body_products_product_right_top_right">
                                  {convertToEuroCent(product.verkaufspreis) +
                                    "€"}
                                </div>
                              </div>
                              <div className="home_body_products_product_right_bottom">
                                <div
                                  className="home_body_products_product_right_bottom_space"
                                  onClick={() =>
                                    handleNavigateClick("product", product)
                                  }></div>
                                <div
                                  className="home_body_products_product_right_bottom_hitbox"
                                  onClick={() => addToCart(product)}>
                                  <div className="home_body_products_product_right_bottom_hitbox_addbtn">
                                    +
                                  </div>
                                  <div className="home_body_products_product_right_bottom_hitbox_space"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                      return null; // Return null if the product title does not include the search input
                    })}
                </>
              ) : (
                <>
                  {categories.map((category) => {
                    return (
                      <>
                        <div
                          className="home_body_products_sectionTitle"
                          id={category}>
                          {category}
                        </div>
                        {products.map((product) => {
                          if (
                            product.category == category &&
                            isEmpty(product.variationVonID)
                          ) {
                            return (
                              <div className="home_body_products_product">
                                <div
                                  className="home_body_products_product_left"
                                  onClick={() =>
                                    handleNavigateClick(
                                      "product",
                                      getFirstSubProduct(product)
                                    )
                                  }>
                                  <img
                                    src={JSON.parse(product.bilder)[0].url}
                                    className="home_body_products_product_left_img"
                                  />
                                </div>
                                <div className="home_body_products_product_right">
                                  <div
                                    className="home_body_products_product_right_top"
                                    onClick={() =>
                                      handleNavigateClick(
                                        "product",
                                        getFirstSubProduct(product)
                                      )
                                    }>
                                    <div className="home_body_products_product_right_top_left">
                                      {product.titel}
                                    </div>
                                    <div className="home_body_products_product_right_top_right">
                                      {convertToEuroCent(
                                        product.verkaufspreis
                                      ) + "€"}
                                    </div>
                                  </div>
                                  <div className="home_body_products_product_right_bottom">
                                    <div
                                      className="home_body_products_product_right_bottom_space"
                                      onClick={() =>
                                        handleNavigateClick(
                                          "product",
                                          getFirstSubProduct(product)
                                        )
                                      }></div>
                                    <div
                                      className="home_body_products_product_right_bottom_hitbox"
                                      onClick={() => addToCart(product)}>
                                      <div className="home_body_products_product_right_bottom_hitbox_addbtn">
                                        +
                                      </div>
                                      <div className="home_body_products_product_right_bottom_hitbox_space"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </>
                    );
                  })}
                </>
              )}
            </div>
          </div>
          <div className="home_sidebar">
            <div className="home_sidebar_sticky">
              <Cart
                isMobile={isMobile}
                apiURL={apiURL}
                authToken={authToken}
                products={products}
                cart={cart}
                setCart={setCart}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="home">
      {searchActive ? (
        <div className="home_topnav">
          <div className="home_topnav_searchbar">
            <input
              className="home_topnav_searchbar_input"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Suche nach einem Produkt"
              autocomplete={"off"}
              type="text"
              id={"searchInput"}
            />
            <div
              className="home_topnav_searchbar_close"
              onClick={() => setSearchActive(false)}>
              <CloseIcon />
            </div>
          </div>
        </div>
      ) : (
        <div className="product_topnav">
          <div className="product_topnav_left">{guests[id].name}</div>
          <div className="product_topnav_right" onClick={() => navigate("/")}>
            <CloseIcon />
          </div>
        </div>
      )}

      <div className="home_body">
        <div className="home_body_products">
          {searchActive ? (
            <>
              <div className="home_body_products_sectionTitle">
                Suchergebnisse für {searchInput}
              </div>
              {!isEmpty(products) &&
                searchInput.length > 2 &&
                products.map((product) => {
                  if (
                    product.titel
                      .toLowerCase()
                      .includes(searchInput.trim().toLowerCase())
                  ) {
                    return (
                      <div
                        className="home_body_products_product"
                        key={product.id}>
                        <div
                          className="home_body_products_product_left"
                          onClick={() =>
                            handleNavigateClick(
                              "product",
                              getFirstSubProduct(product)
                            )
                          }>
                          <img
                            src={JSON.parse(product.bilder)[0].url}
                            className="home_body_products_product_left_img"
                          />
                        </div>
                        <div className="home_body_products_product_right">
                          <div
                            className="home_body_products_product_right_top"
                            onClick={() =>
                              handleNavigateClick(
                                "product",
                                getFirstSubProduct(product)
                              )
                            }>
                            <div className="home_body_products_product_right_top_left">
                              {product.titel}
                            </div>
                            <div className="home_body_products_product_right_top_right">
                              {convertToEuroCent(product.verkaufspreis) + "€"}
                            </div>
                          </div>
                          <div className="home_body_products_product_right_bottom">
                            <div
                              className="home_body_products_product_right_bottom_space"
                              onClick={() =>
                                handleNavigateClick(
                                  "product",
                                  getFirstSubProduct(product)
                                )
                              }></div>
                            <div
                              className="home_body_products_product_right_bottom_hitbox"
                              onClick={() => addToCart(product)}>
                              <div className="home_body_products_product_right_bottom_hitbox_addbtn">
                                +
                              </div>
                              <div className="home_body_products_product_right_bottom_hitbox_space"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                  return null; // Return null if the product title does not include the search input
                })}
            </>
          ) : (
            <>
              {categories.map((category) => {
                return (
                  <>
                    <div
                      className="home_body_products_sectionTitle"
                      id={category}>
                      {category}
                    </div>
                    {products.map((product) => {
                      if (
                        product.category == category &&
                        isEmpty(product.variationVonID)
                      ) {
                        return (
                          <div className="home_body_products_product">
                            <div
                              className="home_body_products_product_left"
                              onClick={() =>
                                handleNavigateClick(
                                  "product",
                                  getFirstSubProduct(product)
                                )
                              }>
                              <img
                                src={JSON.parse(product.bilder)[0].url}
                                className="home_body_products_product_left_img"
                              />
                            </div>
                            <div className="home_body_products_product_right">
                              <div
                                className="home_body_products_product_right_top"
                                onClick={() =>
                                  handleNavigateClick(
                                    "product",
                                    getFirstSubProduct(product)
                                  )
                                }>
                                <div className="home_body_products_product_right_top_left">
                                  {product.titel}
                                </div>
                                <div className="home_body_products_product_right_top_right">
                                  {convertToEuroCent(product.verkaufspreis) +
                                    "€"}
                                </div>
                              </div>
                              <div className="home_body_products_product_right_bottom">
                                <div
                                  className="home_body_products_product_right_bottom_space"
                                  onClick={() =>
                                    handleNavigateClick(
                                      "product",
                                      getFirstSubProduct(product)
                                    )
                                  }></div>
                                <div
                                  className="home_body_products_product_right_bottom_hitbox"
                                  onClick={() => addToCart(product)}>
                                  <div className="home_body_products_product_right_bottom_hitbox_addbtn">
                                    +
                                  </div>
                                  <div className="home_body_products_product_right_bottom_hitbox_space"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </>
                );
              })}
            </>
          )}
        </div>
      </div>
      {!isEmpty(cart) && (
        <div className="home_bottombar">
          <div
            className="home_bottombar_button"
            onClick={() => handleNavigateClick("cart")}>
            Warenkorb{"(" + convertToEuroCent(countPrices()) + "€)"}
          </div>
        </div>
      )}
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 30px;
  color: #474747;
`;

const CloseIcon = styled(MdClose)`
  font-size: 25px;
  color: #222222;
`;

const SearchIcon = styled(MdSearch)`
  font-size: 25px;
  color: #474747;
`;

const ArrowForwardIcon = styled(MdArrowForwardIos)`
  font-size: 18px;
  color: #474747;
`;
